<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 lg:tw-px-16 tw-px-3">
    <div>
      <div>
        <spinner v-if="loading"></spinner>

        <div v-if="errored && errorMsg.status == '404'">

          <div class="alert alert-warning" role="alert">
            {{ errorMsg.statusText }}
          </div>

        </div>

        <div v-if="vendor.showcasework && !loading" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">
          <div class="tw-text-sm mb-3 tw-col-span-8">
            <span class="tw-text-red">Wedding offers</span> /
            {{ vendor.showcasework.title }}
          </div>
          <div v-if="images" class="tw-col-span-8">
            <gallery :images="images" />
          </div>
          <div class="tw-col-span-8">
            <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-mb-4 lg:tw-mb-0 tw-justify-between tw-items-start tw-mt-4">
              <div>
                <h1 class="tw-font-semibold tw-text-xl tw-leading-relaxed tw-mb-2">{{ vendor.showcasework.title }}</h1>
                <div class="tw-flex tw-items-center tw-text-sm tw-mb-4">
                  <img :src="vendor.avatar" class="tw-h-6 tw-mr-2 tw-rounded-full" />
                  {{ vendor.companies.name || vendor.name }}
                </div>
                <div class="tw-mb-6 tw-flex tw-items-center">

                  <v-rating
                      :value="vendor.averageRating"
                      color="red"
                      background-color="red"
                      class="tw-flex"
                      dense
                      emptyIcon='mdi-heart-outline'
                      fullIcon='mdi-heart'
                      halfIcon='mdi-heart-half-full'
                      half-increments
                      readonly
                      size="10"
                  ></v-rating>

                  <span style="font-size: 8px" class="tw-text-red tw-font-semibold tw-ml-1">{{ vendor.averageRating }}</span>

                </div>
              </div>
              <div class="tw-flex tw-items-center tw-space-x-4">
                <div v-if="vendor.showcasework.youtube" href="#" @click="playVideo('youtube')" class="tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-n1 tw-px-4 tw-py-2 tw-flex tw-items-center">
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black tw-mr-2">YouTube</span>
                  <img src="@/assets/img/youtube.png" class="tw-w-6 tw-w-6" width="6" height="6" alt="Play YouTube Video">
                </div>
                <div v-if="vendor.showcasework.vimeo" href="#" @click="playVideo('vimeo')" class="tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-n1 tw-px-4 tw-py-2 tw-flex tw-items-center">
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black tw-mr-2">Vimeo</span>
                  <img src="@/assets/img/vimeo.png" class="tw-w-6 tw-w-6" width="6" height="6" alt="Play Vimeo Video">
                </div>
                <div @click="messageDialog = true" class="tw-cursor-pointer tw-border tw-rounded-xl tw-border-gray-n1 tw-px-4 tw-py-2 tw-flex tw-items-center">
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black">Message vendor</span>
                  <span class="tw-cursor-pointer tw-bg-black tw-rounded-xl tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-ml-4 tw-text-white">
                    <send-icon class="tw-w-6 tw-w-6" />
                  </span>
                </div>
                <div @click="dialogQuote = true" class="tw-cursor-pointer tw-border tw-rounded-xl tw-border-red tw-bg-red tw-text-white tw-px-4 tw-py-4">
                  <span class="tw-text-sm tw-font-semibold tw-leading-relaxed">Request a Quote</span>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-text-sm tw-col-span-8 lg:tw-col-span-6">

            <h5 class="tw-font-semibold tw-mb-3">Description</h5>
            <p class="tw-leading-relaxed">
              {{ vendor.showcasework.description }}
            </p>



            <div class="tw-border-b tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
              <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
                <a @click.prevent="productTab = 'offers'" href="#" :class="[productTab === 'offers' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']">
                  <span>Available offers <span v-show="products.length">({{products.length}})</span></span>
                </a>
                <a @click.prevent="productTab = 'quotes'" :class="[productTab === 'quotes' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']" href="#">
                  <span>Quotes requests <span v-show="quotes.length">({{quotes.length}})</span></span>
                </a>
              </nav>

            </div>
            <div class="tw-flex tw-font-semibold tw-mb-3 tw-pb-3 tw-border-bottom tw-border-gray-n0 tw-leading-relaxed">
              <h5></h5>
              <h5></h5>
            </div>
            <div v-if="products.length > 0" v-show="productTab === 'offers'">
              <div class="tw-space-y-2 tw-mt-1">
                <!-- Dates selection -->
                  <v-dialog
                      ref="dialog"
                      v-model="datesSeleted"
                      :return-value.sync="dates"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                          class="text-caption remove-bg-fix"
                          dense
                          v-model="dates"
                          multiple
                          chips
                          deletable-chips
                          small-chips

                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @focus="datesSeleted=true"
                          @click:append="datesSeleted=true"
                      >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                          <v-chip
                            v-bind="attrs"
                            :color="`${item.color} lighten-3`"
                            :input-value="selected"
                            label
                            small
                          >
                            <span class="pr-2">
                              {{ dateFormat(item) }}
                            </span>
                            <v-icon
                              small
                              @click="parent.selectItem(item)"
                            >
                              $delete
                            </v-icon>
                          </v-chip>
                        </template>

                        <template v-slot:label>
                          <span class="tw-font-semibold tw-text-xl">{{ $t('filter.wedding_date') }}</span>
                        </template>
                        <template v-slot:append>
                          <svg width="26" height="27" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6667 3.16663H3.33333C2.59695 3.16663 2 3.76358 2 4.49996V13.8333C2 14.5697 2.59695 15.1666 3.33333 15.1666H12.6667C13.403 15.1666 14 14.5697 14 13.8333V4.49996C14 3.76358 13.403 3.16663 12.6667 3.16663Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.6665 1.83337V4.50004" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.3335 1.83337V4.50004" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2 7.16663H14" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </template>
                      </v-combobox>
                    </template>
                    <v-date-picker v-model="dates" multiple no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="datesSeleted = false">{{ $t('general.cancel') }}</v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(dates);datesSeleted = false">{{ $t('general.ok') }}</v-btn>
                    </v-date-picker>
                  </v-dialog>
                <!-- END -->
              </div>
              <div class="tw-space-y-4">

                <VendorShowcaseProduct
                    v-for="(product) in products" :key="product.id"
                    :product=product
                    :vendor=vendor
                    :selectDates="selectDates"
                    :preselectedDates="dates"
                    :ploading="ploading"
                    @ordered="ordered($event)"
                    @error="error($event)"
                ></VendorShowcaseProduct>
              </div>
            </div>
            <div v-if="quotes.length > 0" v-show="productTab === 'quotes'">
              <div class="tw-space-y-4">
                <VendorPackagesTmp
                    v-for="(quote) in quotes" :key="quote.id"
                    :product=quote
                    :vendor=vendor
                    :selectDates="quote.quote.dates.length > 0 ? quote.quote.dates[0].datef:null"
                    :preselectedDates="[]"
                    :ploading="ploading"
                    @ordered="ordered($event)"
                    @error="error($event)"
                ></VendorPackagesTmp>
              </div>
            </div>
            <spinner v-else-if="!loading && ploading"></spinner>
            <div>
              <h5 class="tw-font-semibold tw-mb-3 tw-mt-4">Additional information</h5>
              <router-link class="tw-text-red tw-inline-flex tw-items-center"
                           :to="{path: '/vendor/'+vendor.id+'/terms-and-conditions' }"
              >
                {{$t('vendor.toc')}}
                <arrow-up-right-icon class="tw-w-4 tw-h-4 tw-ml-1" />
              </router-link>
            </div>

          </div>
          <div class="tw-col-span-8 lg:tw-col-span-2 tw-space-y-4">

            <div class="tw-flex tw-space-x-2">
              <div v-if="vendor.companies && (vendor.companies.instagram_link || vendor.companies.facebook_link)" class="tw-flex tw-space-x-3 tw-items-center tw-mt-4">
                <span v-if="vendor.companies.facebook_link" @click="goTo(vendor.companies.facebook_link)" 
                class="facebook tw-cursor-pointer tw-h-8 tw-w-8 tw-inline-flex tw-justify-center tw-items-center tw-rounded-full">
                  <facebook-icon class="tw-w-5 tw-h-5" />
                </span>
                <span v-if="vendor.companies.instagram_link" @click="goTo(vendor.companies.instagram_link)" 
                class="instagram tw-cursor-pointer tw-bg-gray-n3 tw-h-8 tw-w-8 tw-inline-flex tw-justify-center tw-items-center tw-rounded-full tw-text-white">
                  <instagram-icon class="tw-w-5 tw-h-5" />
                </span>
              </div>
            </div>

            <div class="tw-flex tw-items-center">
              <h4 class="tw-leading-relaxed tw-font-semibold">Ratings</h4>
              <a href="#" @click.prevent="dialogReviews = true" class="tw-text-red tw-ml-auto tw-text-xs tw-font-semibold tw-no-underline">Read more</a>
            </div>

            <div v-for="(rating, index) in ratings.slice(0, 3)" :key="index" class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-4">
              <div class="tw-flex tw-items-center tw-text-sm tw-mb-4">
                <img :src="rating.author.avatar" class="tw-h-6 tw-w-6 tw-mr-2 tw-rounded-full" />
                {{ rating.author.name }}
                <div class="tw-ml-auto tw-flex tw-items-center">
                  <v-rating
                      :value="rating.rating"
                      color="red"
                      background-color="red"
                      class="tw-flex"
                      dense
                      emptyIcon='mdi-heart-outline'
                      fullIcon='mdi-heart'
                      halfIcon='mdi-heart-half-full'
                      half-increments
                      readonly
                      size="10"
                  ></v-rating>
                  <span style="font-size: 8px" class="tw-text-red tw-font-semibold tw-ml-1">{{ rating.rating }}</span>
                </div>
              </div>
              <div class="tw-font-sm tw-leading-relaxed">
                {{ rating.body }}
              </div>
            </div>

            <div @click="dialogReviews = true" class="tw-cursor-pointer">
              <span class="tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-black tw-inline-flex tw-items-center">
                <pen-tool-icon size="1x" class="tw-mr-1" />
                Write a Review
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="!loading" class="tw-text-left tw-grid tw-grid-cols-8 tw-gap-x-8">
          <div class="tw-text-sm mb-3 tw-col-span-8">
            <span class="tw-text-red">Wedding Offers</span> /
            No Showcase Work created.
          </div>

        </div>

      </div>

      <!-- Ordered msg-->
      <v-dialog
          v-if="this.vendor.settings"
          v-model="orderStatus"
          hide-overlay
          persistent
          width="600"
      >
        <v-card v-if="1==2">
          <v-card-title class="headline grey lighten-2">
            Added to Cart / {{ this.vendor.settings.auto_booking === true ? 'Auto-Booking' : 'Booking request' }}
          </v-card-title>
          <v-card-text class="mt-2">

            {{ this.ordermsg }}
            <v-divider/>
            <div v-if="this.vendor.settings.auto_booking === true">
              This booking request will be automatically approved.
            </div>
            <div v-else>
              This booking request needs to be approved by Vendor. Mango & Lola allows 48 hours for the vendor to reply,
              but most do reply within a few hours.
            </div>

          </v-card-text>
          <v-card-actions>
            <v-btn outlined small @click="orderStatus=!orderStatus">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Your item has been added to cart.
          </v-card-title>
          <v-card-text class="mt-2">

            <v-btn color="yellow" rounded
                   @click="openCart()"
            >
              Proceed to check out
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined small @click="orderStatus=!orderStatus">
              Continue to shopping
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Order error msg-->
      <v-dialog
          v-if="this.vendor.settings"
          v-model="orderError"
          hide-overlay
          persistent
          width="600"
      >
        <v-card>
          <v-card-title class="headline red lighten-2">
            Oops, something went wrong!
          </v-card-title>
          <v-card-text class="mt-2">

            {{ this.ordermsg }}

          </v-card-text>
          <v-card-actions>
            <v-btn outlined small @click="orderError=!orderError">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Gallery -->
      <v-dialog
          v-model="gallery"
          transition="dialog-bottom-transition"
      >

        <v-card>
          <v-toolbar dark color="secondary">
            <v-btn icon dark @click="gallery = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="vendor.showcasework">{{ vendor.showcasework.name }} gallery</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="gallery = false">Close</v-btn>
            </v-toolbar-items>
          </v-toolbar>


          <div id="images" class="col" v-if="vendor.showcasework">


            <v-carousel
                v-if="!loading && vendor.showcasework.images"

                reverse-transition="fade-transition"
                transition="fade-transition"
                v-model="imgItem"

            >

              <v-carousel-item
                  v-for="(item,i) in vendor.showcasework.images"
                  :key="i"

                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
              >

              </v-carousel-item>

            </v-carousel>


          </div>


        </v-card>

      </v-dialog>

      <!-- Modal for reviews -->
      <v-dialog
          v-model="dialogReviews"
          width="800"
      >

        <v-card class="mx-auto">
          <v-card-title class="headline grey lighten-2">
            {{ vendor.name }}

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="dialogReviews = false"
            >
              Close
            </v-btn>
          </v-card-title>

          <v-card-text>
            <Reviews
                :vendor=vendor
            ></Reviews>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn

                text
                @click="dialogReviews = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal for request quote -->
      <v-dialog
          v-model="dialogQuote"
          max-width="600"
          v-if="vendor">

        <v-card class="mx-auto">
          <v-card-title class="headline grey lighten-2">
            {{ vendor.name }}

            <v-spacer></v-spacer>
            <v-btn
                text
                @click="dialogQuote = false"
            >
              Close
            </v-btn>
          </v-card-title>

          <v-card-text>
            <QuoteForm
                :vendor=vendor
                :chat=chat
            ></QuoteForm>
          </v-card-text>

        </v-card>
      </v-dialog>

      <!-- Modal for messages -->
      <v-dialog v-model="messageDialog" max-width="600" persistent>
        <div>
          <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">
            <h3 class="tw-text-xl tw-font-semibold tw-leading-8">
              Send a message
            </h3>
            <div class="tw-space-y-1">
                    <div class="tw-mt-4">
                        <v-btn
                            v-if="!user"
                            text
                            x-small
                            :to="{ name: 'login' }"
                            class="routerLink"
                            >          
                              <v-icon x-small left>
                              mdi-account-outline
                              </v-icon>
                                {{ $t('message.login_required') }}
                        </v-btn>
                    </div>
              <label for="message_guests" class="tw-mb-0 tw-text-sm tw-font-semibold tw-leading-relaxed tw-text-gray-n3">Content</label>
              <span class="tw-text-sm tw-leading-relaxed tw-text-gray-n4">

              <textarea id="message_guests" rows="5" v-model="message"
                         class="tw-border tw-border-solid tw-p-4 tw-border-gray-n1 tw-rounded-xl tw-w-full tw-p-0 tw-text-sm tw-text-gray-n4 tw-placeholder-gray-n3 tw-leading-relaxed"></textarea>
              </span>
            </div>

            <div class="tw-mt-10 tw-grid tw-grid-cols-2 tw-gap-x-4">
              <span @click="clearMessage" class="tw-cursor-pointer tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                Cancel
              </span>
              <button :disabled="!message"
                  class="disabled:tw-opacity-50 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4"
                  @click="sendMessage"
              >
                Send
              </button>
            </div>

          </div>

        </div>
      </v-dialog>

      <!-- Video -->
      <v-dialog
          v-model="showVideoDialog"
          @click:outside="stopPlayVideo"
          :max-width="560"
      >
        <v-card elevation="0">
          <v-toolbar dark color="gray lighten-1">
            <v-btn icon dark @click="stopPlayVideo">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-xs">{{ $t('vendor.showcasework.video_gallery') }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>


          <div class="col">

            <Video
              :youtube=null
              :vimeo=null
              :video=videoToShow
              :type=videoType
              :stopPlay=stopPlay
              :autoplay=true
              />

          </div>

        </v-card>

      </v-dialog>




    </div>
  </div>


</template>


<script>


import VendorShowcaseProduct from '../components/VendorShowcaseProduct.vue'
//import Calendar from '../components/CalendarByVendor.vue'
import QuoteForm from '../components/QuoteForm.vue';
import Reviews from '../components/Reviews.vue';
//import ShowcaseworkFilter from '../components/DataFilter.vue';
//import Messages from '../components/Message.vue';

import { mapGetters } from 'vuex'
import Gallery from "@/components/Gallery";
import Video from "@/components/Video.vue";
import { ArrowUpRightIcon, InstagramIcon, FacebookIcon, SendIcon, PenToolIcon } from "vue-feather-icons";

export default {
  //middleware: ['guest'],
  components: {
    VendorShowcaseProduct,
    //Calendar,
    QuoteForm,
    Reviews,
    //ShowcaseworkFilter,
    //Messages
    Gallery,
    ArrowUpRightIcon,
    InstagramIcon,
    FacebookIcon,
    SendIcon,
    PenToolIcon,
    Video
  },
  data() {
    return {
      productTab: 'offers',
      message: null,
      messageDialog: false,
      active_tab: null,
      addonNr: 0,
      imgItem: 0,
      orderStatus: false,
      orderError: false,
      ordermsg: '',
      vendor: {
        showcasework: {
          vimeo: null,
          youtube:null
        }
      },
      slug: {},
      products: {},
      quotes: {},
      showcasework: {},
      settings: {},
      images: [],
      dialogReviews: false,
      dialogQuote: false,
      showVideoDialog: false,
      videoToShow: null,
      videoType: null,
      stopPlay: false,
      chat: null,
      loading: false,
      drawerR: true,
      drawerL: true,
      items: [
        {title: 'Contact us', icon: 'mdi-email'},
        {title: 'Instagram', icon: 'mdi-instagram'},
        {title: 'Facebook', icon: 'mdi-facebook'},
      ],
      mini: true,
      miniR: false,
      showcalendar: false,
      selectDates: [],
      datesSeleted:false,
      dates: [],
      gallery: false,
      errored: false,
      errorMsg: '',
      ploading: false,
      qloading: false,
      ratings: [],
      filterDates: null
    }
  },
  mounted() {

    const params = this.filterParams;
    params["filter[category_in]"] = null;
    this.$store.dispatch('filter/setFilterParams', params);
    this.getVendor();
    /*
    if (localStorage.getItem("filterDates")){
      this.filterDates = JSON.parse(localStorage.getItem("filterDates"));
      this.dates = this.filterDates;
    }
    */

    if (this.preselectedDates.length > 0) {
      //console.log(this.preselectedDates);
      this.dates = this.preselectedDates.split(',');
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated',
      filterParams: 'filter/getFilterParams',
      getViewMyCart: 'cart/getViewMyCart',
      //getOrderStatus: 'cart/getOrderStatus'
    }),
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '3em';
        default:
          return '5em';
      }
    },
    miniSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        case 'sm':
          return true;
        case 'md':
          return true;
        case 'lg':
          return false;
        case 'xl':
          return false;
        default:
          return false;
      }
    },
    maxSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false;
        case 'sm':
          return false;
        case 'md':
          return false;
        case 'lg':
          return true;
        case 'xl':
          return true;
        default:
          return true;
      }
    },
    preselectedDates() {
      return this.filterParams && this.filterParams["filter[availability_dates_in]"] ? this.filterParams["filter[availability_dates_in]"] : [];
    },
  },
  watch: {
    vendor: function () {
      // this.showcasework = this.vendor.showcasework;
      // this.settings = this.vendor.settings;
    },
    /*
    getOrderStatus: function () {
      if (this.getOrderStatus) {
        this.getProducts(false, this.vendor.id);
        let status = false;
        this.$store.dispatch('cart/setOrderStatus', {status});
        let cart = false;
        this.$store.dispatch('cart/setCartWindow', {cart});
      }
    },
    */
    dates: function () {

        if(!this.vendor.id) return;
        const params = this.filterParams;

        if (!this.dates || this.dates === "" || this.dates.length === 0){
          params["filter[availability_dates_in]"] = null;
          this.$store.dispatch('filter/setFilterParams', params);
          this.getProducts(true, this.vendor.id);
          return;
        }

        //if(!this.preselectedDates) return;
        //if(JSON.stringify(this.dates) === JSON.stringify(this.preselectedDates.split(','))) return;


        params["filter[availability_dates_in]"] = this.dates.toString();
        this.$store.dispatch('filter/setFilterParams', params);
        //console.log(this.filterParams);
        this.getProducts(true, this.vendor.id);
    },
  },
  methods: {
    playVideo(type){

      //console.log(type);
      this.stopPlay = false;
      if(this.vendor.showcasework && type == 'youtube'){
        this.videoType=type;
        this.videoToShow=this.vendor.showcasework.youtube;
        this.showVideoDialog=true;
      }
      if(this.vendor.showcasework && type == 'vimeo'){
        this.videoType=type;
        this.videoToShow=this.vendor.showcasework.vimeo;
        this.showVideoDialog=true;
      }
    },
    stopPlayVideo(){
      this.stopPlay = true;
      this.showVideoDialog=false;
    },
    goTo(url){

      if (url.substring(0, 7) !== 'http://' && url.substring(0, 8) !== 'https://'){
        url = 'https://' + url;
      }
      window.open(url, '_blank');
    },
    clearMessage() {
      this.message = null;
      this.messageDialog = false;
    },
    sendMessage() {

      if(!this.user) this.$router.push({name: 'login'});

      let messageObj = {
        to_user_id: this.vendor.id,
        vendor_id: this.vendor.id,
        message: this.message,
      }

      this.$http.post('/api/messages', messageObj)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
          }).finally(() => {
        this.clearMessage();
      });
    },
    quoteRequest(data) {
      this.chat = data.item;
      // this.dialogQuote = true; // prebacen dialog u message direktno.

    },
    async openCart() {
      let cart = true;
      await this.$store.dispatch('cart/setCartWindow', {cart});
      this.orderStatus = !this.orderStatus;
    },
    async ordered(order) {
      this.ordermsg = order;
      // await this.getVendor(); // napomena: Ukljuciti ako ima direktan order
      this.orderStatus = true;

    },
    error(order) {
      this.ordermsg = order;
      this.orderError = true;

    },
    onselectedDates(dates) {
      this.selectDates = dates;
    },
    async getVendorIdBySlug(slug) {
      let uri = '/api/company/slug';

      await this.$http.get(uri, {
        params: {
          slug: slug
        }
      })
          .then((response) => {
            //console.log(response.data.data);
            this.slug = response.data.data;
          })
          .catch(error => {
            this.$router.push({name: 'feed'});
            this.errorMsg = error;
            this.errored = true;
          });

    },
    async getVendor() {
      this.loading = true;

      let id = this.$route.params.id;
      //console.log(id);
      if (isNaN(id)) {
        // it's a string
        //console.log("it's a string");
        await this.getVendorIdBySlug(id);
        //console.log(this.slug);
        id = this.slug.id;
      }

      let uri = '/api/vendors/' + id + "?include=companies,categories";
      this.$http.get(uri)
          .then((response) => {
            this.vendor = response.data.data;
            this.images = response.data.data.showcasework ? response.data.data.showcasework.images: [];
            this.showcalendar = true;
            this.getProducts(false, this.vendor.id);
            this.getQuotes(false, this.vendor.id);
            this.getRatings(this.vendor.id);
          })
          .catch(error => {
            console.log(error);
            //this.$router.push({name: 'feed'});
            this.errorMsg = error;
            this.errored = true;
          })
          .finally(() =>
              //this.vendor.showcasework ? this.images = this.vendor.showcasework.images:null,
              this.loading = false
          );
    },
    async getProducts(e, id) {

      if(this.dates){
        e = true;
      }
      // this.$route.params.id
      this.ploading = true;
      const param = e == true ? this.filterParams : null;
      //const param = this.filterParams ? this.filterParams:null;

      console.log("Loading products...");
      let uri = '/api/products/vendor/' + id;
      await this.$http.get(uri, {params: param})
          .then((response) => {
            this.products = response.data.data
          })
          .catch(error => {
            this.errorMsg = error;
            this.errored = true;
          }).finally(() => this.ploading = false);
    },
    getQuotes(e, id) {

      this.qloading = true;
      const param = e == true ? this.filterParams : null;

      let uri = '/api/quotes/vendor/' + id;
      this.$http.get(uri, {params: param})
          .then((response) => {
            this.quotes = response.data.data
          })
          .catch(error => {
            this.errorMsg = error;
            this.errored = true;
          }).finally(() => this.qloading = false);
    },
    getRatings(id) {
      let uri = '/api/ratings/' + id;
      this.$http.get(uri)
          .then((response) => {
            this.ratings = response.data.data
          })
          .catch(error => {
            this.errorMsg = error;
            this.errored = true;
          });
    },
  }
}
</script>
<style scoped>
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, .25);
}
.facebook {
  background: #3B5998;
  color: white;
}
</style>
