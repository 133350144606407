<template>
  <div>
      <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-px-6 tw-py-4">


        <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap">
          <div v-if="(product.type.name === 'Product' || product.type.name === 'Package')" :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !product.image}" class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4" style="flex-basis: 200px; height: 140px">
            <gallery v-if="product.images.length > 0" :images="product.images" :id="'offer-gallery' + product.id" :limit="1" thumbWrapperClass=""
                     :gallery-setup="{}" default-image-class="tw-h-36" />
            <div v-else><image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/></div>
          </div>
          <div class="tw-flex-grow tw-mt-4 lg:tw-mt-0">
            <div class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-items-center">
              <div class="tw-text-xl tw-w-full lg:tw-w-auto tw-leading-relaxed tw-font-semibold tw-text-black tw-mr-auto">
                {{ product.name }}
              </div>


                <a
                    class="tw-px-8 tw-py-4 tw-border tw-border-black tw-text-black tw-rounded-xl tw-inline-flex tw-font-semibold tw-leading-relaxed"
                    @click="seeDetails(product.id)"

                >
                 <spinner v-if="ploading" :small="true" class="tw-text-black"></spinner>
                 <div v-else>
                  <span v-show="!datesAlert">{{$t('vendor.showcasework.details')}}</span>

                 </div>
                </a>


            </div>
            <div v-if="product.is_date_filter_on" class="tw-flex">
              <div>

                <div v-if="product.unavailability.length" class="tw-py-2 tw-flex tw-text-xs tw-text-red tw-space-x-1 tw-items-center" >
                  <calendar-icon size="1x" />
                  <div v-for="(unavailability,index) in product.unavailability" :key="index">
                    <span v-for="(date,index) in unavailability.dates" :key="index">
                      {{dateFormat(date.datef)}}
                    </span>
                  </div>
                </div>
                <div v-if="availableDates.length > 0" class="tw-py-2 tw-flex tw-text-xs tw-text-green tw-space-x-1 tw-items-center">
                  <calendar-icon size="1x" />
                    <span v-for="(datum,index) in availableDates" :key="index">
                      {{dateFormat(datum)}}
                    </span>
                </div>
              </div>

            </div>
            <div class="tw-flex">
              <div v-if="product.quote">
                <div class="tw-py-2 tw-flex tw-text-xs tw-text-green tw-space-x-1 tw-items-center">
                  <calendar-icon size="1x" />
                    <span v-for="(datum,index) in product.quote.dates" :key="index">
                      | {{dateFormat(datum.datef)}}
                    </span>
                </div>
              </div>
            </div>

            <div>
              {{product.categories.map(item => item.name).join(',') }}

            </div>

            <div>
              <div v-if="product.type.name === 'Package' || product.type.name === 'Product'" class="tw-text-sm tw-text-gray-n3 tw-font-semibold">
                {{ $t('vendor.offer.price') }}: {{ product.total_price_without_discount | currency }} |
                {{ $t('vendor.offer.discount') }} ({{ product.discount}}%) | <span class="tw-text-black">Total: {{product.total_price_with_discount | currency}}</span>

              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

</template>

<script>

import Gallery from "@/components/Gallery";
import { ImageIcon,  CalendarIcon  } from "vue-feather-icons";

export default {
  name: 'VendorShowcaseProduct',
  props: ['vendor', 'product', 'selectDates', 'preselectedDates','ploading'],

  components: {
    //VendorAddons,
    //ProductsImageGallery,
    Gallery,
    ImageIcon,
    CalendarIcon
  },
  mounted(){

  },
  watch: {
    selectDates: function () {
      // used for Calendar
      this.selectedProduct.dates = this.selectDates;
    },
    event: function () {
      //this.selectedProduct.event = this.event;
      this.selectedProduct.event_id = this.event == 0 ? null : this.event;
      //console.log(this.selectedProduct.event_id);

    },
    preselectedDates: function(){
      this.datesAlert = false;
    }

  },

  data() {
    return {
      orderConfirmation: false,
      ordering: false,
      loginDialog: false,
      BookingOptionHelp: false,
      datesAlert: false,
      loading: false,
      sc: 'hidden',
      availability: [],
      unavailability: [],
      selectedProduct: {
        id: null,
        addons: null,
        dates: null,
        vendor_id: this.vendor.id,
        comment: '',
        country: null,
        city: null,
        event: null,
        shipping_id: null,
        quantity: 1
      },
    }
  },

  computed: {
    availableDates() {

      if(this.product && this.vendor){
        if(!this.vendor.settings.set_my_available_dates){
          return this.getDifferences(this.product.unavailability);
        }else{
          return this.returnDates(this.product.availability);
        }

      }else{
        return null;
      }

    },
    attributes() {
      return this.availability.map(t => ({
        key: t.id,
        dates: t.json_dates,
        customData: t,
        test: 1
      }));
    },

  },
  methods: {

    seeDetails(id){
      if(this.ploading) return;
      this.$router.push({name: 'showcasework-details.show', params: { id: id }});
    },
 
    getDifferences(data){
      return this.preselectedDates.filter(item => !this.returnDates(data).includes(item)) ?? null;
    },
    returnDates(availability) {
      var date = [];
      availability.forEach(element => {
        element.dates.forEach(element => {
          date = date.concat(element.datef);
        })
      })
      return date;
    },

  }

}
</script>
