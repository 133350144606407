<template>
  <span v-bind="$props" v-on="$listeners" class="tw-text-xs tw-text-red">{{ msg }}</span>
</template>

<script>

export default {
  props: ['msg'],
}

</script>
