<template>
  <textarea v-bind:value="value" v-bind="$attrs"
         v-on:input="$emit('input', $event.target.value)"
         class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
  </textarea>
</template>

<script>

export default {
  props: ['value'],
}

</script>
