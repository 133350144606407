<template>
  <div>
    <!-- Youtube Embed -->
    <LazyYoutube 
    v-if="this.type == 'youtube'" 
    ref="youtubeLazyVideo" 
    :aspectRatio=aspectRatio
    :maxWidth=maxWidth
    :src="this.video"
    :autoplay=autoplay />

    <!-- Vimeo Embed -->
    <LazyVimeo
    v-if="this.type == 'vimeo'"
    ref="vimeoLazyVideo" 
    :maxWidth=maxWidth
    :aspectRatio=aspectRatio  
    :src="this.video"
    :autoplay=autoplay />


  </div>
</template>

<script>

import { LazyYoutube, LazyVimeo } from "vue-lazytube";

export default {
  name: 'Video',
  components: {
          LazyYoutube,
          LazyVimeo
      },
  props: {
    aspectRatio: { type: String, default: "16:9" },
    maxWidth: { type: String, default: "560px" },
    youtube: { type: String, default: null },
    vimeo: { type: String, default: null },
    video: { type: String, default: null },
    type: { type: String, default: null },
    stopPlay: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false }
  },
    watch: {
      stopPlay: function () {
        //console.log("stopVideo",this.stopPlay);
        if(!this.stopPlay) return;
        if(this.type == 'youtube') this.$refs["youtubeLazyVideo"]['stopVideo']();
        if(this.type == 'vimeo') this.$refs["vimeoLazyVideo"]['stopVideo']();

      },
    }
}
</script>