<template>
<div>

    <spinner v-if="loading" />

    <div v-if="ratingCollections.length && !loading">
        <div class="row">
          <div class="col" style="text-align: center;">
            <div class="tw-mt-2">
              <v-expansion-panels flat focusable v-model="panel">
                <v-expansion-panel :disabled="ratingCollections.length == 0" >
                  <v-expansion-panel-header 
                    class="text-xs">
                                      
                        {{vendor.averageRating}}/5 ({{ratings.length}} reviews)
                        
                    <template v-slot:actions>

                      <span small rounded text>{{ $t('rating.write_review') }}</span>
                    
                    </template>
                   
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                
                    <v-form ref="form" v-model="valid" :lazy-validation="lazy"  @submit.prevent="create">
                      <v-card flat elevation="0">
                        <v-card-title>
                        <v-input :value="item.rating" :rules="[rules.required]">
                          <v-spacer/>
                          <v-rating
                            
                            v-model="item.rating"
                            color="red"
                            background-color="grey darken-1"
                            emptyIcon='mdi-heart-outline'
                            fullIcon='mdi-heart'
                            halfIcon='mdi-heart-half-full'
                            half-increments
                            hover
                            large
                          ></v-rating>    
                        </v-input>   
                        <v-spacer/>
                
                        </v-card-title>
                        <v-card-text>

                        <!-- My ratingCollections -->
                        <v-chip-group
                          v-model="item.rating_collector_id"
                          :mandatory="true"
                          hidden
                        >
                                <template v-for="(citem,index) in ratingCollections">
                                    <v-tooltip 
                                      top
                                      :key="index"
                                      >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-chip 
                                          v-bind="attrs"
                                          v-on="on"
                                          filter outlined :value="citem.id">
                                              {{citem.id}}
                                        </v-chip>
                                      </template>
                                   {{citem.id}}
                                    </v-tooltip>
                                </template>

                        </v-chip-group>

                          <v-textarea 
                            :rules="[rules.required,rules.maxlength(1500)]"
                            v-model="item.description"
                            :label="$t('rating.comment')"
                            outlined
                            counter
                            >
                          </v-textarea>
            

                        <button :disabled="!valid"
                            class="disabled:tw-opacity-50 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4"
                            >
                            {{ $t('rating.save') }}
                        </button>  
                        </v-card-text>

                      </v-card>
                    </v-form>

            
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </div>
          </div>
        </div>

    </div>  

    <div v-if="ratings.length && !loading" class="tw-divide-y tw-divide-red">
      <div v-for="(item,index) in ratings" :key="index" class="tw-mb-4">
          <div class="tw-flex tw-justify-between tw-mt-5">
            <div class="tw-flex tw-items-center tw-mb-4">
              <img :src="item.author.avatar" class="tw-h-9 tw-w-9 tw-mr-3 tw-rounded-full"/>
              <span>
                  {{ item.author.name }}

                  <div class="tw-flex tw-items-center">
                    <v-rating
                        :value="item.rating"
                        color="red"
                        background-color="red"
                        class="tw-flex"
                        dense
                        emptyIcon='mdi-heart-outline'
                        fullIcon='mdi-heart'
                        halfIcon='mdi-heart-half-full'
                        half-increments
                        readonly
                        size="12"
                    ></v-rating>
                    <span style="font-size: 10px" class="tw-text-red tw-font-semibold tw-ml-1">{{ item.rating }}</span>
                  </div>
                </span>
            </div>

            <span class="tw-text-gray-n3 tw-text-xs">
                 {{item.created_at}}
            </span>
          </div>
          <span class="tw-mt-4">
              {{item.body}}
          </span>
      </div>
      <div>
        <div class="tw-mt-5">
          <v-pagination
              v-if="!loading"
              v-model="pegination"
              :length="meta.last_page"
              :total-visible="meta.total"
              color="tw-bg-red"
          ></v-pagination>
        </div>
      </div>

    </div>
    <div v-else-if="!loading">
    <p>{{ $t('rating.not_found') }}</p>
    </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'reviews',
    props: ['vendor'],
    data() {
      return {
        panel: null,
        pegination: 1,
        meta: [],
        loading: false,
        valid: false,
        lazy: false,
        item:{
            description: null,
            rating: null,
            vendor_id: null,
            rating_collector_id:null
        },
        ratings: [],
        ratingCollections: [],
        addReview: false,
        rules: {
          required: v => !!v || 'This field is required',
          maxlength: len => v => (v || '').length <= len || `Invalid character max length ${len}`,   
        }
      }
},
mounted (){
  this.getItem();
},
computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
},
watch: {
                "pegination": function(){
                        this.getItem();
                },  
},
    methods: {
        selectedRating(data){
          this.item.rating = data;
        },

        async create() {

          await this.$refs.form.validate();
          if (!this.valid) return;

           let uri = '/api/ratings';
           this.item.vendor_id = this.vendor.id;

            this.$http.post(uri, this.item)
            .then((response) => {
                this.getItem();
                this.addReview = false;
                this.clear();
                this.$toast.success(response.data.message, "Success")
            });
        },
        clear(){
          this.item.description = null;
          this.item.rating = null;
          this.panel = null;
        },
        getItem(){
                  this.loading = true;
                  let id = this.vendor.id ? this.vendor.id:this.$route.params.id

                  const params = {      
                    "page": this.pegination ? this.pegination : this.$route.query.page
                  };

                  let uri = '/api/ratings/' + id;
                  this.$http.get(uri,{params}).then((response) => {
                      this.ratings = response.data.data,
                      this.meta = response.data.meta,
                      this.loading = false
                      this.getRatingCollections();
                  });
        },
      getRatingCollections(){

        if(this.user){
            this.loading = true;

            const params = 
              {
                    "filter[pending]" : 1,
                    "filter[vendor_id]" : this.vendor.id
              };

            let uri = 'api/rating/collections';
            this.$http.get(uri,{ params: params }).then((response) => {
                this.ratingCollections = response.data.data,
                this.loading = false
            });
        }
        
      },        
            
    }
};
</script>


