<template>
  <label v-bind="$attrs" v-on="$listeners"
         class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
    <slot/>
    &nbsp;
    <span v-if="required" class="tw-text-red">*</span>
  </label>
</template>

<script>

export default {
  props: {
    required: {
      default: false,
      type: Boolean
    },
  }
}

</script>
