<template>
    <div>
      <form ref="form"  @submit.prevent="onSubmit">
        <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">
          <h3 class="tw-text-xl tw-font-semibold tw-leading-8">
            {{$t('quote.quote_request')}}
          </h3>
           <div class="tw-space-y-1 tw-mt-4">
            <div v-show="message" class="alert alert-info" role="alert">
              {{ message }}
              <div v-for="(error,index) in apierrors" :key="index">
                <span>{{ error[0] }}</span>
              </div>
            </div>
          </div>

           <div class="tw-space-y-1 tw-col-span-4">
              <div class="tw-mt-4">
                <ml-label required for="description">Quote Description</ml-label>
                <ml-textarea v-model="form.quote_description" id="description" name="description" required/>
                <ml-error-msg :msg="errors['description']" />
                </div>
              <div class="tw-mt-4">

                    <v-dialog
                        ref="dialog"
                        v-model="datemenu"
                        :return-value.sync="form.eventDay"
                        persistent
                        width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <ml-label required for="eventDay">Wedding Date</ml-label>
                            <v-combobox
                                class="text-caption tw-cursor-pointer"
                                v-model="form.eventDay"
                                @input="$v.form.eventDay.$touch()"
                                @blur="$v.form.eventDay.$touch()"
                                append-icon=""
                                prepend-icon=""
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                                :error-messages="quote_dateErrors"
                                required
                                lebel="Select Wedding Date"
                                >
                                <template v-slot:label>
                                    <span class="caption">Wedding Date</span>
                                </template>
                              <template v-slot:prepend>
                                <v-icon v-bind="attrs"
                                        v-on="on" small :color="form.eventDay ? 'pink':''">icon-wedding-date</v-icon>
                              </template>
                                </v-combobox>

                        </template>
                        <v-date-picker
                            v-model="form.eventDay"
                            :min="(new Date()).toISOString().split('T')[0]"
                            no-title
                            scrollable
                            :events="functionEvents"
                        >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="datemenu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(form.eventDay)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </div>
             <div v-if="!user">
               <div class="tw-mt-4">
                 <ml-label required for="name">Name</ml-label>
                 <div class="tw-mt-2">
                   <ml-input-text @change="validateForm" v-model="form.name" id="name" name="name" required/>
                   <ml-error-msg :msg="errors['name']" />
                 </div>
               </div>
               <div class="tw-mt-4">
                  <ml-label required for="email">Email</ml-label>
                  <div class="tw-mt-2">
                    <ml-input-text @change="validateForm" v-model="form.email" id="email" name="email" required/>
                    <ml-error-msg :msg="errors['email']" />
                  </div>
                </div>

                <div class="tw-mt-6 tw-flex tw-items-left tw-space-x-3">
                  <input v-model="form.terms_and_conditions" id="terms_and_conditions" type="checkbox" value="1"
                         class="w-4 h-4 tw-text-red tw-bg-gray-n tw-ring-1 tw-ring-black rounded tw-border-black focus:tw-ring-red
                    dark:focus:tw-ring-red-100 dark:tw-ring-offset-gray-n1 focus:tw-ring-2 dark:tw-bg-gray-n1 dark:tw-border-gray-n2">

                  <label for="terms_and_conditions" class="tw-text-xs tw-leading-relaxed tw-text-black">
                    By submitting this form, I agree to be bound by the <router-link target="_blank" class="tw-text-red" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
                    and <router-link target="_blank" class="tw-text-red" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>.
                  </label>

                </div>
               <ml-error-msg :msg="errors['terms_and_conditions']" />
             </div>
           </div>
          <div class="tw-mt-10 tw-grid tw-grid-cols-2 tw-gap-x-4">
                <span @click="clearQuote"
                      class="tw-cursor-pointer tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-solid tw-border-black tw-text-base tw-font-medium tw-rounded-xl tw-text-black tw-bg-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
                  Cancel
                </span>
            <button  :disabled="!valid || !form.eventDay"
                     class="disabled:tw-opacity-50 tw-flex tw-justify-center tw-w-full tw-px-6 tw-py-4 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-gray-n4 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-gray-n4">
              {{ $t('quote.quote_request') }}
            </button>
          </div>
        </div>
      </form>
    </div>
</template>


<script>

import { required,maxLength } from "vuelidate/lib/validators"
import { validationMixin } from 'vuelidate'
import Form from 'vform'
import { mapGetters } from 'vuex'
import MlLabel from "@/components/layout/form/MlLabel";
import MlTextarea from "@/components/layout/form/MlTextarea";
import MlInputText from "@/components/layout/form/MlInputText";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
export default {
middleware: ['guest'],
mixins: [validationMixin],
components: {
    MlLabel,MlInputText,MlErrorMsg,MlTextarea
  },
props: ['vendor','chat'],
    data () {
            return {
              vendor2: {
                unavailability: [],
                },
                datemenu: false,
                agreement: false,
                dialog: false,
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
                form: new Form({
                    vendor_id: this.vendor.id,
                    quote_description: '',
                    eventDay: null,
                    agreement: null,
                    message_id: null,
                    email: null,
                    name: null,
                    terms_and_conditions: null
                }),
                data: [],
                meta: {},
                links: {},
                loading: false,
                errored: false,
                //editing: false
                valid: true,
                lazy: true,
                message: null,
                apierrors: null,
                rules: {
                    //maxLength: v => v.length <= 500 || 'Max 500 characters',
                    maxlength: len => v => (v || '').length <= len || `Invalid character max length ${len}`,
                    email: v => !!(v || '').match(/@/) || 'Please enter a valid email',
                    length: len => v => (v || '').length >= len || `Invalid character length, required ${len}`,
                    password: v => !!(v || '').match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/) ||
                    'Password must contain an upper case letter, a numeric character, and a special character',
                    required: v => !!v || 'This field is required',                    
                },
                arrayEvents: null,
                errors:[],
              error: null
            }
    },
    validations: {
            form: {
                quote_description: {required},
                eventDay: {required},
                maxLength: maxLength(10)
            }
    }, 

    mounted(){
      if(this.chat != null){
          this.form.message_id = this.chat.id;
          this.form.quote_description = this.chat.message;
      }
      this.getUnavailability();

    },
  computed: {
    quote_dateErrors () {
      const errors = []
      if (!this.$v.form.eventDay.$dirty) return errors
      //!this.$v.name.maxLength && errors.push('Name must be at most 10 characters long')
      !this.$v.form.eventDay.required && errors.push('Wedding day is required.')
      return errors
    },
    ...mapGetters({
        user: 'auth/user',
        authenticated: 'auth/authenticated',
    }),
    dates() {
      return this.form.eventDay.map(day => day.date);
    },
    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },
    unavailableDates(){
    
        let dates = [];
        this.vendor2.unavailability.forEach(element0 => {
            element0.dates.forEach(element1 => {
                dates.push({
                    date: element1.datef,
                });
            });
        });

        return dates.map(day => day.date);

    }
  },
  watch: {
      'form.eventDay' : function () {

          if (this.unavailableDates.includes(this.form.eventDay)){
            this.form.eventDay = null;
          }
      },
    'form.terms_and_conditions': function (){
      this.validateForm();
    }
  },
  methods: {

    getUnavailability(){
      if(this.vendor.id) this.getVendor(this.vendor.id);
    },
    async getVendor(id){

      this.loading = true;
      let uri = '/api/vendors/' + id;
      await this.$http.get(uri)
          .then((response) => {
            this.vendor2 = response.data.data;
          })
          .catch(error => {
            //console.log(error);
            //this.$router.push({name: 'feed'});
            this.errorMsg = error;
            this.errored = true;
            this.loading = false;
          });
          this.loading = false;
    },
    functionEvents (date) {
        if (this.unavailableDates.includes(date)){
            return ['red'];
        }else{
            return false;
        }
        //if (this.unavailableDates.includes(date)) return ['red', '#00f']


    },
    formatDate (date) {
    if (!date) return null

    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
    },
    parseDate (date) {
    if (!date) return null

    const [month, day, year] = date.split('/')
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    onDayClick(day) {
        const idx = this.form.days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            this.form.days.splice(idx, 1);
        } else {
            this.form.days.push({
            id: day.id,
            date: day.date,
            });
        }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validateForm() {

      console.log("validate form...");
      this.errors = [];
      let label = '';

      if(!this.user){
        if (!this.form.name) {
          label = "Name Required";
          this.errors.push(label);
          this.errors["name"] = label;
        }
        if (!this.form.terms_and_conditions) {
          label = "Terms and Conditions Required";
          this.errors.push(label);
          this.errors["terms_and_conditions"] = label;
        }

        if (!this.form.email) {
          label = "Email Required";
          this.errors.push(label);
          this.errors["email"] = label;
        } else if (!this.validEmail(this.form.email)) {
          label = "Valid Email Required";
          this.errors.push(label);
          this.errors["email"] = label;
        }
      }


      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    onSubmit () {
      this.$v.$touch();
      if(this.validateForm()) this.addNewQuote();
    },
    clear () {
        this.$v.$reset();
        this.form.quote_description = '';
        this.form.eventDay = null;
        this.form.email = null;
        this.error = null;
        //this.form.agreement = null;

    },
    async addNewQuote () {
                this.$v.$touch()
                //await this.$refs.form.validate();
                if (!this.valid) return;

                this.form.vendor_id = this.vendor.id;

                await this.form.post('api/quotes')
                    .then(({data})  => {
                    this.data = data
                    this.message = data.message;
                    this.clear();
                    })
                    .catch((error) => {
                        this.message = error.data.message;
                        this.apierrors = error.data.errors;
                        // Error
                        if (error.response) {
                            //console.log('Error response', error.response);
                        } else if (error.request) {
                          this.error = error.data.message;
                          this.$toast.info(error.data.message, "info");
                          //console.log('Error request.response', error.request.response);
                        } else {
                            //console.log('Error message', error.message);
                        }
                        //console.log('Error', error);
                    });
                },
    clearQuote() {
      this.clear();
      this.$emit('close');
    },
  },


}

</script>