<template>
  <div>
    <gallery
        v-if="!thumbOnly"
        :id="id"
        :options="galleryOptions"
        :images="galleryImages"
        :index="galleryIndex"
        @close="galleryIndex = null">
      <template v-slot:prev>
        <arrow-left-icon class="tw-h-4 tw-w-4 tw-text-black"/>
      </template>
      <template v-slot:next>
        <arrow-right-icon class="tw-h-4 tw-w-4 tw-text-black"/>
      </template>
    </gallery>

    <div>
      <div :class="thumbWrapperClass">
        <div v-for="(image,index) in thumbImages" :key="index" class="tw-rounded-lg tw-overflow-hidden tw-relative"
             :class="[{'tw-border-white tw-border-2' : showBorder}, getGalleryItemClass(index)]">
          <slot name="image-action" v-bind:image="image"></slot>
          <div v-if="index === limit - 1 && galleryImages.length > limit"
               class="tw-bg-black tw-bg-opacity-40 tw-absolute tw-flex tw-w-full tw-h-full tw-items-center tw-justify-center tw-text-white tw-text-2xl tw-pointer-events-none">
            +{{ galleryImages.length - limit }}
          </div>
          <img :src="image.src" @click="setGalleryIndex(index)" :class="{'tw-cursor-pointer': !thumbOnly}"
               class="tw-block tw-w-full tw-h-full tw-object-cover tw-rounded-lg" :alt="image.name"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueGallery from "vue-gallery";
import { ArrowLeftIcon, ArrowRightIcon } from "vue-feather-icons";

export default {
  name: 'Gallery',
  components: {
    'gallery': VueGallery,
    ArrowLeftIcon,
    ArrowRightIcon,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: 'showcase-gallery',
    },
    thumbOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    images: {
      type: Array,
      required: true,
      deep: true,
      immediate: true,
    },
    limit: {
      type: Number,
      default: 5,
      immediate: true,
    },
    defaultImageClass: {
      type: String,
      default: 'tw-h-40 tw-hidden lg:tw-block',
      immediate: true,
    },
    thumbWrapperClass: {
      type: String,
      default: 'tw-grid lg:tw-grid-cols-4 lg:tw-grid-rows-2',
      immediate: true,
    },
    imageClass: {
      type: String,
      default: 'tw-grid lg:tw-grid-cols-4 lg:tw-grid-rows-2',
      immediate: true,
    },
    showBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    gallerySetup: {
      type: Object,
      default: function () {
        return {
          '5': ['lg:tw-row-span-2 tw-col-span-2 tw-h-80'],
          '4': ['lg:tw-row-span-2 tw-h-80', 'tw-row-span-2 tw-h-80', 'tw-row-span-2 tw-h-80', 'tw-row-span-2 tw-h-80'],
          '3': ['lg:tw-row-span-2 tw-col-span-2 tw-h-80', 'tw-row-span-2 tw-h-80', 'tw-row-span-2 tw-h-80'],
          '2': ['lg:tw-row-span-2 tw-col-span-2 tw-h-80', 'tw-row-span-2 tw-col-span-2 tw-h-80'],
          '1': ['lg:tw-row-span-2 tw-col-span-2 tw-h-80'],
        }
      }
    },
    indexGallerySetup: {
      type: Object,
      default: function () {
        return {}
      }
    },
    galleryOptions: {
      type: Object,
      default: function () {
        return {
          closeOnSlideClick: true,
        }
      }
    },
  },
  data() {
    return {
      galleryIndex: null,
      galleryImages: [],
      thumbImages: [],
    }
  },
  mounted() {
    this.setValues();
  },
  methods: {
    setValues() {
      this.galleryImages = this.images.map(image => image.src);
      this.thumbImages = [...this.images];
      this.thumbImages.length = Math.min(this.images.length, this.limit);
    },
    getGalleryItemClass(index) {
      if (this.gallerySetup[this.thumbImages.length] && this.gallerySetup[this.thumbImages.length][index]) {
        return this.gallerySetup[this.thumbImages.length][index];
      } else if (this.indexGallerySetup[index]) {
        return this.indexGallerySetup[index];
      } else {
        return this.defaultImageClass;
      }
    },
    setGalleryIndex(index) {
      if (!this.thumbOnly) {
        this.galleryIndex = index;
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    images: {
      deep: true,
      handler() {
        this.setValues();
      }
    },
    limit: function (newLimit, oldLimit) {
      if (newLimit !== oldLimit) {
        this.thumbImages = [...this.images];
        this.thumbImages.length = Math.min(this.images.length, this.limit);
      }
    }
  },
}

</script>
