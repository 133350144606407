<template>
  <input v-bind:value="value" v-bind="$attrs"
         v-on="inputListeners"
         type="text"
         class="tw-appearance-none tw-block tw-w-full tw-px-4 tw-py-3 tw-border tw-border-solid tw-border-gray-n1 tw-rounded-xl tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
</template>

<script>

export default {
  props: ['value'],
  computed: {
    inputListeners: function () {
      let vm = this

      return Object.assign({},
          this.$listeners,
          {
            input: function (event) {
              vm.$emit('input', event.target.value)
            }
          }
      )
    }
  },
}

</script>
